import $ from 'jquery';
import 'bootstrap';
import 'bootstrap4-notify';

// Libs
// import $ from 'jquery';
// import 'bootstrap';

window.jQuery = $;
window.$ = $;

$.notifyDefaults({
    type       : 'success',
    width      : window.isMobile ? '90%' : '50%',
    z_index    : 2000000001,
    mouse_over : 'pause',
    delay      : 9000,
    placement : {
        from: "top",
        align: "center",
    },
    offset : {
        y : 95,
        x : 20,
    },
});

// /Libs

// Set globals
// window.$ = $;
// /Set globals

// window.$ = window.jQuery = require('jquery');
